import React from 'react';
import {
  PageLayout,
  PageLayoutHeader,
  PageLayoutBody,
  PageTitle,
} from '../components/page-layout';
import SEO from '../components/seo';

const Gallery = () => (
  <PageLayout className="gallery-background">
    <SEO title="Gallery" />
    <PageLayoutHeader />
    <PageTitle>Gallery</PageTitle>
    <PageLayoutBody>Coming soon...</PageLayoutBody>
  </PageLayout>
);

export default Gallery;
